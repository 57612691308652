var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('v-card',{staticClass:"card_action",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.name))]),_c('v-card-text',[_vm._v(_vm._s(_vm.description))])],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"color":"secondary","depressed":"","tile":"","to":'/baza-wiedzy/kategoria-edytuj/'+_vm.category}},[_vm._v(" Edytuj kategorie ")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","tile":"","to":'/baza-wiedzy-nowa/'+_vm.category}},[_vm._v(" Dodaj nową stronę ")])],1)],1),_c('v-data-table',{staticClass:"classic_table",attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"item-key":"name","footer-props":{
      itemsPerPageText : 'Elementów na stronie',
      itemsPerPageAllText : 'Wszystkie',
      itemsPerPageOptions : [15,45,90,-1],
    }},on:{"click:row":_vm.userClick},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("Brak danych")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("Brak wyników")]},proxy:true},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" z "+_vm._s(itemsLength)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }