<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-card flat class="card_action">
      <v-card-title>{{name}}</v-card-title>
      <v-card-text>{{description}}</v-card-text>
    </v-card>

    <v-row justify="end">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Wyszukaj"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col style="text-align: right">
        <v-btn
          color="secondary"
          depressed
          tile
          :to="'/baza-wiedzy/kategoria-edytuj/'+category"
        >
          Edytuj kategorie
        </v-btn>
        <v-btn
          color="primary"
          depressed
          tile
          :to="'/baza-wiedzy-nowa/'+category"
        >
          Dodaj nową stronę
        </v-btn>
      </v-col>
    </v-row>
    
    <v-data-table
      class="classic_table"
      :headers="headers"
      :items="list"
      :search="search"
      item-key="name"
      @click:row="userClick"
      :footer-props="{
        itemsPerPageText : 'Elementów na stronie',
        itemsPerPageAllText : 'Wszystkie',
        itemsPerPageOptions : [15,45,90,-1],
      }"
    >
      <template v-slot:no-data>Brak danych</template>
      <template v-slot:no-results>Brak wyników</template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
      </template>

    </v-data-table>
  </div>
</template>

<script>
// #TODO zrobic edycje strony + zrobic edycje kategorii
// #TODO bedzie inaczej zwracane, tutaj na samej gorze trzeba wyswietlic nazwe kategorii i opis

export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Baza wiedzy',
        disabled: false,
        to: '/baza-wiedzy-lista',
      },
      {
        text: 'Kategoria',
        disabled: true,
        to: '/baza-wiedzy-jeden',
      },
    ],

    category: 0,
    name: '',
    description: '',

    headers: [
      // {
      //   text: 'ID',
      //   align: 'start',
      //   value: 'id',
      // },
      { text: 'Tytuł', value: 'title' },
    ],

    list: [
      {
        id: 1,
        name: 'Nazwa Klienta',
        email: 'email@email.com',
        number: '12313131233'
      },
    ],
    
    search: ''
  }),
  methods: {
    userClick(value) {
      this.$router.push('/baza-wiedzy/zobacz/'+value.id);
    }
  },
  mounted(){
    this.category = this.$route.params.category;
    console.log(this.category);

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/faq_category/'+this.category, data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data.pages;
        this.name = resp.data.name;
        this.description = resp.data.description;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
</style>